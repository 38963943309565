<template>
  <div class=" min-h-full">
    <vs-card>
      <!-- <div class="flex flex-wrap m-5" v-if="page === 1">
        <div class="w-full">
          <vs-chip
            v-if="complianceDetails.status == 'upNext'"
            transparent
            color="primary"
          >
            {{ complianceDetails.status }}
          </vs-chip>
          <vs-chip
            v-else-if="complianceDetails.status == 'overdue'"
            transparent
            color="danger"
          >
            {{ complianceDetails.status }}
          </vs-chip>
          <vs-chip
            v-else-if="complianceDetails.status == 'inReview'"
            transparent
            color="warning"
          >
            {{ complianceDetails.status }}
          </vs-chip>
          <vs-chip
            v-else-if="complianceDetails.status == 'done'"
            transparent
            color="#24c1a0"
          >
            {{ complianceDetails.status }}
          </vs-chip>
          <vs-chip v-else>
            {{ complianceDetails.status }}
          </vs-chip>
        </div>
        <div class="w-full mt-5">
          <p>
            {{ `${complianceMonth} Compliance` }}
          </p>
          <h1 class="mt-2">{{complianceDetails.clinicName}}</h1>
          <h3 class="mt-2">Step 1 of 2 - File Uploads</h3>
          <vs-divider />
        </div>
        <vs-popup
          class="overflow-hidden"
          title="Image Preview"
          :active.sync="popupImageActivo"
        >
          <div class="w-full">
            <div class="w-full">
              <vs-image class="w-full" :key="index" :src="imagePreviewSrc" />
            </div>
            <div class="w-full flex justify-end">
              <vs-button
                size="lg"
                color="danger"
                class="text-right mt-5"
                @click="removePreview"
                >Remove</vs-button
              >
            </div>
          </div>
        </vs-popup>
        <div class="w-full">
          <div class="w-full flex mt-5 flex-no-wrap">
            <div class="w-full">
              <h3>Drug Book</h3>
              <p>Please upload photos of your drugbook from last month</p>
              <div class="w-full flex flex-wrap">
                <div
                  :key="index"
                  class="w-32 m-2"
                  v-for="(image, index) in images.drug"
                  @click="ImagePreview(image.Location, 'drug')"
                >
                  <vs-image
                    class="w-full hover:shadow-lg hover-scale object-cover"
                    :key="index"
                    :src="image.Location"
                  />
                </div>
              </div>
            </div>
            <div class="flex items-center p-5">
              <div
                class="vs-con-loading__container"
                style="text-align: center; position: relative"
              >
                <label
                  class="image-upload"
                  style="
                    border: 1px solid #cccccc;
                    display: inline-block;
                    width: 100%;
                    border-radius: 5px;
                    padding: 10px;
                    border: 1px solid rgba(var(--vs-success), 1);
                    background: transparent !important;
                    color: rgba(var(--vs-success), 1);
                  "
                >
                  Upload More
                  <input
                    type="file"
                    @change="uploadImage($event, 'drug')"
                    id="drugs-file-input"
                    hidden
                    multiple
                  />
                </label>
              </div>
            </div>
          </div>
          <vs-divider />
          <div class="w-full flex mt-5 flex-no-wrap">
            <div class="w-full">
              <h3>Fridge Temperatures</h3>
              <p>
                Please upload photos of your frige temperature monitoring book
              </p>
              <div class="w-full flex flex-wrap">
                <div
                  :key="index"
                  class="w-32 m-2"
                  v-for="(image, index) in images.fridge"
                  @click="ImagePreview(image.Location, 'fridge')"
                >
                  <vs-image
                    class="w-full hover:shadow-lg hover-scale object-cover"
                    :key="index"
                    :src="image.Location"
                  />
                </div>
              </div>
            </div>
            <div class="flex items-center p-5">
              <div
                class="vs-con-loading__container"
                style="text-align: center; position: relative"
              >
                <label
                  class="image-upload"
                  style="
                    border: 1px solid #cccccc;
                    display: inline-block;
                    width: 100%;
                    border-radius: 5px;
                    padding: 10px;
                    border: 1px solid rgba(var(--vs-success), 1);
                    background: transparent !important;
                    color: rgba(var(--vs-success), 1);
                  "
                >
                  Upload More
                  <input
                    type="file"
                    @change="uploadImage($event, 'fridge')"
                    id="fridge-file-input"
                    hidden
                    multiple
                  />
                </label>
              </div>
            </div>
          </div>
          <vs-divider />
          <div class="w-full flex mt-5 flex-no-wrap">
            <div class="w-full">
              <h3>Stock Photos</h3>
              <p>
                Please upload photos of your stock (fridge and cupboard stock)
              </p>
              <div class="w-full flex flex-wrap">
                <div
                  :key="index"
                  class="w-32 m-2"
                  v-for="(image, index) in images.stock"
                  @click="ImagePreview(image.Location, 'stock')"
                >
                  <vs-image
                    class="w-full hover:shadow-lg hover-scale object-cover"
                    :key="index"
                    :src="image.Location"
                  />
                </div>
              </div>
            </div>
            <div class="flex items-center p-5">
              <div
                class="vs-con-loading__container"
                style="text-align: center; position: relative"
              >
                <label
                  class="image-upload"
                  style="
                    border: 1px solid #cccccc;
                    display: inline-block;
                    width: 100%;
                    border-radius: 5px;
                    padding: 10px;
                    border: 1px solid rgba(var(--vs-success), 1);
                    background: transparent !important;
                    color: rgba(var(--vs-success), 1);
                  "
                >
                  Upload More
                  <input
                    type="file"
                    @change="uploadImage($event, 'stock')"
                    id="fridge-file-input"
                    hidden
                    multiple
                  />
                </label>
              </div>
            </div>
          </div>
          <vs-divider />
        </div>
        <div class="w-full flex justify-end items-center">
          <p class="m-5">Step 1 of 2</p>
          <vs-button size="lg" color="primary" @click="saveImages"
            >Next</vs-button
          >
        </div>
      </div> -->
      <div class=" sm:hidden mx-5">
          <h4>Compliance</h4>
        <vs-divider />
      </div>
      <div class="flex-row-reverse w-full flex flex-wrap m-5">
         <div class=" w-4/12 sm:w-full items-center flex justify-end sm:justify-start pr-10">
          <div :class="complianceDetails.status.toLowerCase() + ' rounded-full text-sm text-center px-3 py-1 inline-block font-semibold'">
            {{getStatusData(complianceDetails.status)}}
          </div>
        </div>
        <div class="w-8/12 mt-5 sm:w-full">
          <h4>
            {{ `${complianceMonth} Compliance` }}
          </h4>
          <h1>{{complianceDetails.clinicName}}</h1>
          
          <h4 v-if="!reschedule" class="hidden sm:block">Step 2 of 2 - Video call booking</h4>
          <h5 class="block sm:hidden">Video call booking</h5>
        </div>
          <vs-divider />
        <div id="calendlyContainer" class="w-full">
          <!-- url="https://calendly.com/tech-180/monthly-compliance-check" -->
            <vue-calendly
              :url="calendlyURL"
              :height="800"
            ></vue-calendly>
          </div>
        <div class="w-full flex justify-end items-center pr-10">
          <p class="m-5">Step 2 of 2</p>
          <vs-button size="lg" color="primary" :disabled="disableDoneButton" @click="popupActive = true"
            >Done!</vs-button
          >
        </div>
      </div>
    </vs-card>
    <vs-popup class="holamundo" :active.sync="popupActive" title="" @close="popupActive=false">
      <div class="flex flex-wrap items-center justify-center">
        <div class="w-full text-center">
          <h1>Thanks!</h1>
        </div>
        <div class="w-full text-center mt-5 mb-10">
          <p>
            Our team will review your submission.
          </p>
          <p>
            You will be notified once completed.
          </p>
        </div>
        <div class="w-full text-center mt-5 mb-10">
          <p>
            Have some feedback you’d like to provide?
          </p><p>
            Fill out our 2 minutre ‘feedback’ form below
          </p>
        </div>
        <!-- <div class="w-full text-center mb-10">
          <vs-button size="lg" color="success" type="border"
            >Feedback</vs-button
          >
        </div>
        <div class="w-full flex items-end justify-end">
          <vs-button size="lg" color="primary" @click="redirectToComplianceList"
            >Continue ></vs-button
          >
        </div> -->
        <vs-divider />
        <div class="w-full flex justify-between">
          <div>
            <vs-button size="lg" color="success" type="border" @click="redirectToFeedbackForm">
              Feedback
            </vs-button>
          </div>
          <div>
            <vs-button size="lg" color="primary" @click="redirectToComplianceList">
              Continue >
            </vs-button>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import Vue from "vue";
import VueCalendly from "vue-calendly";
import axios from "axios";
import { mapActions } from "vuex";
import moment from 'moment';
Vue.use(VueCalendly);

export default {
  inject: ['getRoleRouteName'],
  props: ["reschedule","redirectRoute"],
  data() {
    return {
      page: +this.$route.params.stepNumber,
      popupActive: false,
      imagePreviewSrc: "",
      status: "In Review",
      clinicId: "",
      complianceMonth: "",
      complianceDetails: {},
      images: {
        drug: [],
        fridge: [],
        stock: [],
      },
      disableDoneButton : true,
      calendlyURL: process.env.VUE_APP_CALENDLY_URL,
    };
  },
  // components: {
  //   Review,
  // },
  methods: {
    ...mapActions("monthlyCompliance", [
      "udpatebookingDetails",
      "uploadFiles",
      "updatePhotos",
      "fetchDetails",
      "startCompliance"
    ]),

    ImagePreview(src, imagefor = "") {
      this.popupImageActivo = true;
      this.imagePreviewSrc = { src: src, imagefor: imagefor };
    },
    removePreview() {
      const index = _.findIndex(this.images[this.imagePreviewSrc.imagefor], [
        "Location",
        this.imagePreviewSrc.src,
      ]);
      this.images[this.imagePreviewSrc.imagefor].splice(index, 1);
      this.imagePreviewSrc = "";
      this.popupImageActivo = false;
    },
    async getDetails(clinicId) {
      const res = await this.fetchDetails({ clinicId: clinicId });
      if (res.data.data.length > 0) {
        this.complianceDetails = res.data.data[0];
        this.complianceMonth = moment(res.data.data[0].updatedAt).format(
          "MMMM"
        );
        if(res.data.data.bookingDate){
            this.disableDoneButton = false;
        }
        this.images.drug = _.filter(res.data.data[0].uploadedPhotos, [
          "group",
          "drugbook",
        ]).map((item) => ({ Location: item.photoLink }));
        this.images.fridge = _.filter(res.data.data[0].uploadedPhotos, [
          "group",
          "fridgetemperature",
        ]).map((item) => ({ Location: item.photoLink }));
        this.images.stock = _.filter(res.data.data[0].uploadedPhotos, [
          "group",
          "stockPhotos",
        ]).map((item) => ({ Location: item.photoLink }));
      }
    },
    async saveImages() {
      try {
        this.$vs.loading();
        const drugbook = this.images.drug.map((item) => ({
          group: "drugbook",
          photoLink: item.Location,
        }));
        const fridgetemperature = this.images.fridge.map((item) => ({
          group: "fridgetemperature",
          photoLink: item.Location,
        }));
        const stockPhotos = this.images.stock.map((item) => ({
          group: "stockPhotos",
          photoLink: item.Location,
        }));
        const data = {
          payload: {
            uploadedPhotos: [...drugbook, ...fridgetemperature, ...stockPhotos],
          },
          clinicId: this.clinicId,
        };
        const res = await this.updatePhotos(data);
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Success",
          text: "Monthly Compliance successfully saved.",
          color: "success",
        });
        this.page = 2;
      } catch (error) {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Failed",
          text: "Failed to update Monthly Compliance. Try again!",
          color: "danger",
        });
      }
    },
    async uploadImage(event, imagefor = "") {
      try {
        this.$vs.loading();
        let files = new FormData();
        const fileArray = Object.keys(event.target.files).map((item) => item);
        fileArray.forEach((item) => {
          files.append(`files[${item}]`, event.target.files[item]);
        });

        const res = await this.uploadFiles(files);
        this.images[imagefor] = [...this.images[imagefor], ...res.data];
        this.$vs.loading.close();
      } catch (err) {
        this.$vs.loading.close();
        if (err && err.status === 415) {
          this.$vs.notify({
            title: "Upload Failed",
            text: "File Format Not Supported",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        } else {
          this.$vs.notify({
            title: "Upload Failed",
            text: "Please try again later.",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      }
    },

    async updateDetails(payload) {
      this.$vs.loading();
      const res = await this.udpatebookingDetails({
        clinicId: this.clinicId,
        payload: payload,
      });
      this.$vs.loading.close();
    },
    async getEventDetails(uri) {
      const res = await axios.get(uri, {
        headers: {
          Authorization: `Bearer ${process.env.VUE_APP_CALENDLY_TOKEN}`,
        },
      });
      if (res.data.resource) {
        if(!this.reschedule){
          await this.startCompliance(this.clinicId);
        }
        await this.updateDetails({
          bookingLink: res.data.resource.location.join_url,
          bookingDate: res.data.resource.start_time,
        });
        this.disableDoneButton = false;
      }
    },
    redirectToComplianceList() {
      this.popupActive = false;
      if(this.redirectRoute){
        return setTimeout(() => {this.$router.push({ name: this.redirectRoute })}, 500);
      }
       return setTimeout(() => {this.$router.push({ name: this.getRoleRouteName("Compliance")})}, 500);
    },
    redirectToFeedbackForm() {
      window.open(process.env.VUE_APP_COMPLIANCE_FEEDBACK_HUBSPOT_FORM)
    },
    getStatusData(data){
      const status = {
        upNext: 'Up Next',
        failed: 'Missed',
        skipped: 'Skipped',
        inReview: 'Ready for Review',
        changeRequested: 'Change Requested',
        completed: 'Completed',
      }

      return status[data];
    },
  },
  created: async function () {
    this.clinicId = this.$route.params.clinicId;
    await this.getDetails(this.clinicId);
    var self = this;
    // this.calendlyURL = process.env.VUE_APP_CALENDLY_URL
    window.addEventListener(
      "message",
      async function (e) {
        if (e.data.event === "calendly.event_scheduled") {
          await self.getEventDetails(e.data.payload.event.uri);
        }
      },
      false
    );
  },
  // beforeDestroy() {
  //     window.addEventListener("message", async function(e) {
  //     console.log(e.data.event);
  //     if(e.data.event === "calendly.event_scheduled") {
  //       await self.getEventDetails(e.data.payload.event.uri);
  //     }
  //   }, false)
  // },
};
</script>

<style>
.vs-popup{
  width: 400px !important;
}
.upnext, .inreview {
  background-color: #E5F8EE;
  color: #84CDB8;
}
.skipped {
  background-color: #E2E8E6;
  color: #074230;
}
.failed {
  background-color: #FBE4E4;
  color: #EA5455;
}
.changerequested {
  background-color: #FF9F43;
  color: #FFF2E3;
}
.completed {
  background-color: #E2E8E6;
  color: #074230;
}

</style>
