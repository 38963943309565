<template>
  <compliance-booking :reschedule="true" redirectRoute="superAdminComplianceList"/>
</template>

<script>
import ComplianceBooking from "../../org-owner/compliance/complianceBooking.vue";

export default {
  components: {
    ComplianceBooking,
  },
};
</script>

<style></style>
